import './Perfil.css';
function Perfil() {
    return (
        <>
            <div className="col-md-9 col-sm-12">
                <h1 className="fuente color-principal autor">Kevin Antonio Magaña Monroy</h1>
                <p className="fuente color-secundario">Web Developer</p>
                <p className="color-secundario">
                    Soy un ingeniero en sistemas informáticos graduado de la Universidad de Sonsonate (USO) en El Salvador, especializado en el desarrollo 
                    de aplicaciones web. Tengo experiencia tanto en front-end como en back-end, utilizando frameworks y librerías como React, Angular y 
                    Spring Boot, poseo conocimiento en herramientas como Git, GitHub y Docker,
                    además de contar con proyectos freelance en funcionamiento y desplegados. En la sección de "Mis trabajos" puedes encontrar ejercicios 
                    y algunos de mis proyectos.
                </p>
            </div>
            <div className="container-img col-md-3 col-sm-12">
                <div className='col-md-10 col-sm-12'>
                <img src={require('../../../Imagenes/perfil.png')} />
                </div>
            </div>
        </>
    );
}
export default Perfil;