import "./Preparacion.css";
function Preparacion(props) {
    return (
        <div className="borde">
            <h5 className="color-principal">{props.nivel}</h5>
            <p className="color-secundario">
                {props.grado}
                <br />
                {props.descripcion}
            </p>
            <p className="color-principal">{props.anio}</p>
        </div>
    );
}
export default Preparacion;