import React, { useState, useEffect } from 'react';
import PaginasService from '../../Services/PaginasService';
import './Trabajos.css';
import CartillaTrabajo from './CartillaTrabajo/CartillaTrabajo';
import CsharpImg from '../../Imagenes/csharp.png';
import JavaImg from '../../Imagenes/java.png';
import AngularImg from '../../Imagenes/angular.png';
import ReactImg from '../../Imagenes/react.png';

const Trabajo = () => {
    const [paginas, setPaginas] = useState([]);
    const imagenes = ['csharp.png', 'java.png', 'angular.png', 'react.png'];
    const [loading, setLoading] = useState(true);
    const [selector, setSelector] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await PaginasService.obtenerPaginas();
                //misPaginas tiene todos los objetos de la lista
                const misPaginas = Object.values(response);
                setPaginas(misPaginas);
                setLoading(false);
            } catch (error) {
                console.error('Error al obtener las páginas:', error);
                setPaginas([]);
            }
        };

        fetchData();
    }, []);

    const verTrabajo = (index) => {
        window.location.href = `/trabajo/${index}`;
    }

    const cambiar = (n) => {
        setSelector(n);
    }


    return (
        <div className='contenedor-trabajos'>
            <p className="fuente color-principal">Mis trabajos</p>
            {loading ? (
                <div className='carga color-principal'>
                    <span className="loader color-principal"></span>
                    <p>Espera un momento</p>
                </div>
            ) : (
                <>
                    {selector === 0 ? (
                        <div className="contenedor-imagenes-lenguajes">
                            <div onClick={() => cambiar(1)}><img src={CsharpImg}/></div>
                            <div onClick={() => cambiar(2)}><img src={JavaImg}/></div>
                            <div onClick={() => cambiar(3)}><img src={AngularImg}/></div>
                            <div onClick={() => cambiar(4)}><img src={ReactImg}/></div>
                        </div>
                    ) : (
                        <a onClick={() => cambiar(0)}><i className="bi bi-arrow-left-square-fill retroceder"></i></a>
                    )}
                    {selector !== 0 && (
                        <ul className="trabajos-list">
                            {paginas.slice().reverse().map((pagina, index) => (
                                pagina.lenguaje == selector && (
                                    <li key={index}>
                                        <CartillaTrabajo
                                            imagen={imagenes[pagina.lenguaje - 1]}
                                            descripcion={pagina.titulo}
                                            titulo={pagina.titulo}
                                            descripcionCorta={pagina.descripcionCorta}
                                            index={paginas.length - 1 - index}
                                            verTrabajo={verTrabajo}
                                        />
                                    </li>
                                )
                            ))}
                        </ul>
                    )}

                </>

            )}
        </div>
    );
}

export default Trabajo;
