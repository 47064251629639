import React from "react";
import './Generador.css';

function Generador() {
    return (
        <div>
            <h1>Generador</h1>
            <h1>Generador</h1>
            <h1>Generador</h1>
        </div>
    );
}
export default Generador;